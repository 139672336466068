.amplify-input{
  @apply tw-rounded tw-h-10 tw-border tw-border-solid tw-px-2 tw-py-0 tw-text-base tw-leading-4 tw-w-full tw-border-k-gray-500 tw-text-left !important
}

.amplify-input:focus{
  @apply tw-border-k-purple-400 tw-border-2 !important
}

.amplify-input:focus{
  box-shadow: none !important
}

.amplify-input:hover{
  @apply tw-shadow !important
}

.amplify-field__show-password{
  @apply tw-border tw-border-solid tw-border-k-gray-500 !important
}

.amplify-field__show-password:hover{
  @apply tw-bg-k-purple-100 tw-border-k-purple-400 tw-shadow !important
}

input.amplify-input[type="password"]{
  @apply tw-border-r-0 tw-rounded-r-none !important
}

.amplify-tabs {
  display: none !important;
}

.amplify-button--primary{
  @apply tw-font-semibold tw-rounded-full tw-uppercase tw-bg-k-dark-blue !important
}

.amplify-button--primary:hover{
  @apply tw-bg-k-true-blue !important
}

div[data-amplify-router]{
  @apply tw-rounded-lg tw-shadow-md tw-border-k-gray-500 !important
}

.zone-3{
  height: 560px;
  width: 520px;
  margin: auto;
}

.zone3-bg-image-0 {
  background-image: url('../images/zone3-bg1.jpg') !important;
  display: block !important;
}

.zone3-bg-image-1 {
  background-image: url('../images/zone3-bg2.jpg') !important;
  display: block;
}

.zone3-bg-image-2 {
  background-image: url('../images/zone3-bg3.jpg') !important;
  display: block;
}

.mob-to-hide {
  padding-left: 1em;
  display: flex;
  float: initial;
  flex-direction: column;
  max-width: 50%;
  align-items: center;
}

#mob-to-hide-img img {
  width: 520px !important;
}

.zone1 {
  width: 520px;
  height: 450px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.zone2 {
  margin-top: 30px;
  height: 110px;
  display: inline-block;
  width: 520px;
  height: 450px;
}
.zone3 {
  height: 560px;
  width: 520px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

/* $drop-banner-width = 991px */
@media screen and (max-width: 991px) {
  .mob-to-hide{
    display: none;
  }
}
/* $medium-screen: 768px; */
@media screen and (max-width: 768px) {
  .mob-to-hide{
    height: 70vh !important;
  }
  .mob-to-hide > img {
    width: 100%
  }
}
@media screen and (max-height: 768px) {
  #banner {
    display: none;
  }
}